import React, {useEffect, useRef, useState} from "react";
import { FormControl, FormLabel, Input, Button } from "@chakra-ui/react";
import {useDispatch, useSelector} from "react-redux";
import {
    HOOKUPS_COMPS,
    LINE_POST_AND_TOP_RAIL_COMPS,
    POST_COMPS,
    SEC_COMPS
} from "../../../../../utils/constants";
import StyleAndTypeComponent from "./../sections/style-type-section/StyleAndTypeComponent";
import HeightsComponent from "./../sections/heights/HeightsComponent";
import ColorsComponent from "./../sections/colors/ColorsComponent";
import {extractFileName} from "../../../../../utils/utilFunctions";
import {fetchFenceComponents} from "../../../../../../redux/reducers/fence/fence-component-slice/fenceComponentSlice";
import {fetchFenceComponentCategoriesByFenceTypeId} from "../../../../../../redux/reducers/fence/fence-component-categories/fenceComponentCategoriesSlice";
import {fetchFenceTypes} from "../../../../../../redux/reducers/fence/types-slice/fenceTypeSlice";
import {fetchComponentCollections} from "../../../../../../redux/reducers/fence/comp-collections/componentCollectionsSlice";
import {fetchFenceStyleById, fetchMyFenceStyles} from "../../../../../../redux/reducers/fence/fences/myFenceStylesSlice";
import MyButton from "../../../../../common-comps/MyButton";
import MySpinner from "../../../../../common-comps/spinner/MySpinner";
import IconButton from "../../../../../common-comps/tailwind-css/buttons/IconButton";
import ConfirmationModal from "../../../../../common-comps/modals/ConfirmationModal";
import StyleAndType from "./StyleAndType";
import {AVAILABLE_FENCE_HEIGHTS, FENCE_COMPONENT_COLORS} from "../../../../../../variables/global-data/globalConstants";
import PostInstallationMethod from "./PostInstallationMethod";
import PostInstallationMethods from "../sections/post-installation-methods/PostInstallationMethods";

const FenceBuilder = ({ isOpen, onClose,closeStates, onSave,onUpdate, editMode, isSaving, isUpdating, selectedStyleItem }) => {
    const dispatch = useDispatch();
    const {data:fencesStyle,isLoading:loadingStyleById,status} = useSelector((state) => state.myFenceStyles.fenceStyleById);
    const allFenceStyles = useSelector((state) => state.myFenceStyles.allFenceStyles.data);

    const uploadFenceImgRef = useRef(null)

    // Initialize state based on selectedItem if in edit mode
    const [selectedFenceStyle, setSelectedFenceStyle] = useState('');
    const [selectedFenceType, setSelectedFenceType] = useState('');
    const [selectedFenceHeight, setSelectedFenceHeight] = useState('');
    const [selectedFenceColor, setSelectedFenceColor] = useState('');
    const [selectedTerminalPostData,setSelectedTerminalPostData] = useState(null)
    const [selectedGatePostData,setSelectedGatePostData] = useState(null)
    const [selectedLineAndTopRailData,setSelectedLineAndTopRailData] = useState(null)
    const [selectedSectionComponentsData,setSelectedSectionComponentsData] = useState(null)
    const [selectedHookupsData,setSelectedHookupsData] = useState(null)
    const [selectedGatesData,setSelectedGatesData] = useState(null)

    const [installationMethodDetail, setInstallationMethodDetail] = useState(null);

    const [fenceImage, setFenceImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);

    const [selectedAddedFenceStyle, setSelectedAddedFenceStyle] = useState(null);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);

    // new states

    const [availableHeights, setAvailableHeights] = useState(AVAILABLE_FENCE_HEIGHTS);
    const [fenceDataValue, setFenceDataValue] = useState({style: "", heights: [], colors: [], installation_method: [], gates: [],});
    const [availableColors, setAvailableColors] = useState(Object.keys(FENCE_COMPONENT_COLORS));



    useEffect(() => {
        dispatch(fetchFenceTypes());
        dispatch(fetchMyFenceStyles());
        dispatch(fetchFenceComponents());
        dispatch(fetchComponentCollections());
    }, [dispatch]);


    useEffect(() => {
        if (editMode && selectedStyleItem) {
            // Assuming `selectedItem` has an `id` that can be used to fetch the detailed info
            dispatch(fetchFenceStyleById(selectedStyleItem._id));
        }else if (selectedAddedFenceStyle){
            dispatch(fetchFenceStyleById(selectedAddedFenceStyle._id));

        }
    }, [dispatch, selectedStyleItem, editMode,selectedAddedFenceStyle]);
    useEffect(() => {
        if (fencesStyle && (selectedStyleItem || selectedAddedFenceStyle)) {
            setSelectedItem(fencesStyle);
        }
    }, [fencesStyle]);
    useEffect(() => {
        setFenceImage(selectedItem?.image || '')
        setPreviewUrl(selectedItem?.image || '')
    }, [selectedItem]);

    useEffect(() => {
        if (loadingStyleById){
            setLoadingSpinner(true)

        }else if (!loadingStyleById && status === "succeeded"){
            setLoadingSpinner(false)
        }
    }, [loadingStyleById,status]);

    useEffect(() => {
        if (selectedFenceType) {
            dispatch(fetchFenceComponentCategoriesByFenceTypeId(selectedFenceType._id));
        }
    }, [selectedFenceType, dispatch]);


    const handleFenceImageChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.substr(0, 5) === "image") {
            setFenceImage(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setFenceImage(null);
            setPreviewUrl('');
        }
    };
    const handleSaveFence = async () => {
        const extractIdsWithKeys = (data) => {
            // Check if data is null or undefined, return an empty array if true
            if (!data) return [];

            // Proceed if data is valid
            return Object.entries(data).map(([key, value]) => ({ [key]: value?._id }));
        };

        const gates = selectedGatesData?.map(g=>({...g, component:g.component?._id, hardware:g.hardware?.map(gh=>({...gh, component:gh.component?._id}))}))

        const data = {
            style: selectedFenceStyle || '',
            height: selectedFenceHeight || '',
            fenceType: selectedFenceType?._id,
            color: selectedFenceColor || '',
            terminalPost: extractIdsWithKeys(selectedTerminalPostData),
            gatePost: extractIdsWithKeys(selectedGatePostData?.isSameAsTerminalPost ? selectedTerminalPostData : selectedGatePostData),
            lineAndTopRail: extractIdsWithKeys(selectedLineAndTopRailData),
            sectionComponents: extractIdsWithKeys(selectedSectionComponentsData),
            hookups: extractIdsWithKeys(selectedHookupsData),
            gates: gates || [],
            installationMethodDetail: installationMethodDetail,
        }
        if (onSave) {
            onSave(JSON.stringify(data),fenceImage,()=>{
                setIsConfirmOpen(true)
            });
        }
    };
    const handleUpdateFence = async () => {
        const extractIdsWithKeys = (data) => {
            // Check if data is null or undefined, return an empty array if true
            if (!data) return [];

            // Proceed if data is valid
            return Object.entries(data).map(([key, value]) => ({ [key]: value?._id }));
        };

        const gates = selectedGatesData?.map(g=>({...g, component:g.component?._id, hardware:g.hardware?.map(gh=>({...gh, component:gh.component?._id}))}))

        const data = {
            style: selectedFenceStyle || '',
            height: selectedFenceHeight || '',
            fenceType: selectedFenceType?._id,
            color: selectedFenceColor || '',
            terminalPost: extractIdsWithKeys(selectedTerminalPostData),
            gatePost: extractIdsWithKeys(selectedGatePostData?.isSameAsTerminalPost ? selectedTerminalPostData : selectedGatePostData),
            lineAndTopRail: extractIdsWithKeys(selectedLineAndTopRailData),
            sectionComponents: extractIdsWithKeys(selectedSectionComponentsData),
            hookups: extractIdsWithKeys(selectedHookupsData),
            gates: gates || [],
            installationMethodDetail: installationMethodDetail,
        }
        if (onUpdate) {
            onUpdate(JSON.stringify(data),fenceImage,selectedItem._id,()=>{
                setIsConfirmOpen(true)
            });
        }
    };

    const handleConfirmation = () => {
        onClose()
    };

    const addCustomHeight = () => {
        const newHeight = prompt("Enter new height:");
        if (newHeight && !availableHeights.includes(newHeight)) {
            setAvailableHeights(prevHeights => [...prevHeights, newHeight]);
            setFenceDataValue(prevData => ({
                ...prevData,
                heights: [...prevData.heights, newHeight],
            }));
        }
    };

    const addCustomColor = () => {
        const newColor = prompt("Enter new color:");
        if (newColor && !availableColors.includes(newColor)) {
            setAvailableColors(prevColors => [...prevColors, newColor]);
            setFenceDataValue(prevData => ({
                ...prevData,
                colors: [...prevData.colors, newColor],
            }));
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 h-full w-full z-50 flex items-center justify-center" id="my-modal">
            <div className="relative bg-white border shadow-lg rounded-md w-[80%] h-[98%] flex flex-col">
                {/*Header*/}
                <div className="px-6 py-2 border-b flex justify-between items-center z-10">
                    <p className="text-md font-bold text-blue-500">Fence Builder</p>
                    <button className="modal-close text-2xl" onClick={()=>{
                        setSelectedItem(null)
                        onClose()
                    }}>
                        &times;
                    </button>
                </div>
                {/* Loader displayed when selectedItem is not available and in editMode */}
                {loadingSpinner && <MySpinner/>}
                {/*Body*/}
                <div className="flex-1 overflow-y-auto">
                    <div className="px-6 py-1 space-y-4 text-sm">
                        <StyleAndType
                            setSelectedFenceStyle={setSelectedFenceStyle}
                            setSelectedFenceType={setSelectedFenceType}
                            selectedItem={selectedItem}
                            fenceStyles={allFenceStyles}
                            setSelectedAddedFenceStyle={setSelectedAddedFenceStyle}
                            editMode={editMode}
                        />
                        {/*Heights and Colors*/}
                        <div className="flex justify-between md:space-x-1">
                            {/*Heights and Colors*/}
                            <div className="flex flex-col md:flex-row md:space-x-4">
                                <div className="flex-1">
                                    <FormLabel>Heights available (ft)  </FormLabel>
                                    <div className="flex flex-wrap justify-between -mx-1.4 mt-2">
                                        <div className="grid grid-cols-9 gap-0">
                                            {availableHeights.map((height) => (
                                                <div key={height} className="flex items-center justify-center pr-3">
                                                    <input
                                                        type="checkbox"
                                                        id={`height-${height}`}
                                                        checked={fenceDataValue.heights.includes(height)}
                                                        onChange={(e) => {
                                                            const newHeights = e.target.checked
                                                                ? [...fenceDataValue.heights, height]
                                                                : fenceDataValue.heights.filter((h) => h !== height);
                                                            setFenceDataValue({ ...fenceDataValue, heights: newHeights });
                                                        }}
                                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                                    />
                                                    <label htmlFor={`height-${height}`} className="ml-2 block text-gray-900">
                                                        {height}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <IconButton text="Add Height"
                                                    onClick={addCustomHeight}
                                                    outlined={true} customClasses={"text-xs bg-gray-700 text-white border-gray-700 hover:border-gray-600 hover:bg-gray-600 px-1.5 py-1"}/>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <FormLabel>Colors available </FormLabel>
                                    <div className="flex flex-wrap justify-between -mx-2 mt-1">
                                        <div className="grid grid-cols-5 gap-0">
                                            {availableColors.map((color) => (
                                                <div key={color} className="flex items-center mx-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`color-${color}`}
                                                        checked={fenceDataValue.colors.includes(color)}
                                                        onChange={(e) => {
                                                            const newColors = e.target.checked
                                                                ? [...fenceDataValue.colors, color]
                                                                : fenceDataValue.colors.filter((c) => c !== color);
                                                            setFenceDataValue({ ...fenceDataValue, colors: newColors });
                                                        }}
                                                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                                    />
                                                    <label htmlFor={`color-${color}`} className="ml-2 block text-gray-900">
                                                        {color}
                                                    </label>
                                                </div>
                                            ))}
                                            <IconButton text="Add Color"
                                                        onClick={addCustomColor}
                                                        outlined={true} customClasses={"text-xs bg-gray-700 mt-1 text-white border-gray-700 hover:border-gray-600 hover:bg-gray-600 px-1.5 py-1"}/>
                                        </div>
                                    </div>
                                </div>
                                {/*Post installation methods*/}
                                <PostInstallationMethod setSelectedData={setInstallationMethodDetail} selectedData={selectedItem?.material?.installationMethodDetail}/>

                            </div>
                        </div>
                    </div>
                </div>
                {/*footer*/}
                <div className="px-5 py-1 border-t z-10">
                    <div className="flex justify-end space-x-3">
                        <IconButton text="Cancel" outlined={true} customClasses={"rounded-xl"} onClick={()=>{
                            setSelectedItem(null)
                            onClose()
                        }}/>
                        {
                            editMode && <Button
                                className={`linear relative rounded px-5 text-sm ${
                                    isUpdating
                                        ? "cursor-not-allowed bg-gray-400"
                                        : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                                } font-smaller text-white transition duration-200`}
                                colorScheme="blue"
                                onClick={handleUpdateFence}
                                isLoading={isUpdating}
                                loadingText="Saving..."
                            >
                                {isUpdating && (
                                    <div className="absolute inset-0 flex items-center justify-center">
                                        <div className="h-6 w-6 animate-spin rounded-full border-t-4 border-brand-700 border-t-brand-700 border-r-brand-500 border-b-brand-500"/>
                                    </div>
                                )}
                                Update
                            </Button>
                        }
                        <Button
                            className={`linear relative rounded px-4 text-sm ${
                                isSaving
                                    ? "cursor-not-allowed bg-gray-400"
                                    : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                            } font-smaller text-white transition duration-200`}
                            colorScheme="blue"
                            onClick={handleSaveFence}
                            isLoading={isSaving}
                            loadingText="Saving..."
                        >
                            {isSaving && (
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="h-6 w-6 animate-spin rounded-full border-t-4 border-brand-700 border-t-brand-700 border-r-brand-500 border-b-brand-500"/>
                                </div>
                            )}
                            {editMode ? "Save As New" : "Save"}
                        </Button>
                    </div>
                </div>
            </div>
            {
                isConfirmOpen && <ConfirmationModal
                saveText="Yes"
                cancelText="No"
                mainText="Do you want to close the modal?"
                onCancel={()=>{
                    closeStates && closeStates()
                    setIsConfirmOpen(false)
                }}
                onSubmit={handleConfirmation}
            />
            }
        </div>
    );
};

export default FenceBuilder;
